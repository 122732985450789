<template>
  <v-container fluid class="pb-12 mb-12">
    <v-progress-linear
      color="secondary"
      v-if="$store.state.fetchinAppointments"
      indeterminate
    ></v-progress-linear>
    <v-row class="mt-n3">
      <v-col cols="12" class="px-1 py-0 pa-sm-3">
        <TheCalendar
          :newAgendaDate="newPickedaDate"
          @newFocus="newCalendarFocus"
          class="mt-n6 pt-0"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheCalendar from "@/components/TheCalendar";
import { mapState } from "vuex";

export default {
  name: "calendar-vue",
  components: {
    TheCalendar,
  },
  data() {
    return {
      selectedDate: new Date().toISOString().substr(0, 10),
      selectedDiary: "",
      newPickedaDate: "",
    };
  },
  created() {
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user !== ''){
      if (!this.$store.state.Calendar.allDiaries) {
        this.$store.dispatch("Calendar/listAllDiaries");
      }
    }
    else{
      this.$router.push('/');
    }
  },
  computed: {
    ...mapState(["appointments"]),
  },
  methods: {
    removeColleagueDiary() {
      this.selectedDiary = "";
      this.$store.commit("REMOVE_COLLEAGUE_DIARY");
      this.$store.dispatch("fetchAppointments");
    },
    setNewDate(date) {
      this.newPickedaDate = date;
    },
    newCalendarFocus(date) {
      this.selectedDate = date;
    },
  },
};
</script>

<style>
/* .v-calendar-daily_head-day-label .v-btn--fab.v-size--default {
    height: 26px;
    width: 26px;
} */

@media only screen and (max-width: 960px) {
  .v-calendar-daily_head-day-label .v-btn--fab.v-size--default {
    height: 36px;
    width: 36px;
  }
}
</style>